<template>
  <div>
    <div v-show="!$wait.is('loading-get-setting')">
      <SubAccountCreateForm
        ref="form"
        :prefix="GetAgentGroupUsername(userData)"
        :action-type="actionType"
        :loading="$wait.is('loading-on-save')"
      />

      <!-- PERMISSION TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Permission') }}</span>
          </b-card-title>
        </b-card-header>
        <!-- <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table> -->
        <b-table
          striped
          responsive
          class="mb-0"
          :items="permissionsDataTemp"
        >
          <template #head(module)="data">
            <span class="text-primary">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #head(read)="data">
            <span class="text-primary">{{ data.label.toUpperCase() }}</span>
            <b-form-checkbox
              :checked="checkedAllRead"
              @change="onChangeCheckboxPermissionAll($event, data)"
            >
              {{ $t('All') }}
            </b-form-checkbox>
          </template>
          <template #head(update)="data">
            <span class="text-primary">{{ data.label.toUpperCase() }}</span>
            <b-form-checkbox
              :checked="checkedAllUpdate"
              @change="onChangeCheckboxPermissionAll($event, data)"
            >
              {{ $t('All') }}
            </b-form-checkbox>
          </template>
          <template #head(create)="data">
            <span class="text-primary">{{ data.label.toUpperCase() }}</span>
            <b-form-checkbox
              :checked="checkedAllCreate"
              @change="onChangeCheckboxPermissionAll($event, data)"
            >
              {{ $t('All') }}
            </b-form-checkbox>
          </template>
          <template #head(delete)="data">
            <span class="text-primary">{{ data.label.toUpperCase() }}</span>
            <b-form-checkbox
              :checked="checkedAllDelete"
              @change="onChangeCheckboxPermissionAll($event, data)"
            >
              {{ $t('All') }}
            </b-form-checkbox>
          </template>

          <template #cell(module)="data">
            {{ $t(`PERMISSION_${data.value}`) }}
          </template>
          <template #cell()="data">
            <!-- <b-form-checkbox :checked="data.value" /> -->
            <!-- <b-form-checkbox v-model="permissionFormData[data.item.module]." /> -->
            <!-- { "item": { "module": "Billing", "read": false, "update": false, "create": false }, "index": 2, "field": { "key": "read", "label": "Read" }, "unformatted": false, "value": false, "detailsShowing": false, "rowSelected": false } -->

            <!-- :disabled="permissionFormDataObj[data.item.disabled].includes(data.field.key)" -->
            <b-form-checkbox
              v-if="!permissionFormDataObj[data.item.module]['disabled'].includes(data.field.key)"
              :checked="permissionFormDataObj[data.item.module][data.field.key]"
              @change="onChangeCheckboxPermission($event, data)"
            />
          </template>
        </b-table>
      </b-card>

      <!-- Action Buttons -->
      <Button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :loading="$wait.is('loading-on-save')"
        :text="$t('Save')"
        @click="onSave"
      />
      <Button
        v-if="actionType === 'CREATE'"
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :loading="$wait.is('loading-on-save')"
        :text="$t('Reset')"
        @click="onReset"
      />
    </div>
    <div v-show="$wait.is('loading-get-setting')">
      <loading />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import SubAccountCreateForm from '@/components/sub-accounts/SubAccountCreateForm.vue'
import Button from '@/components/Button.vue'
import store from '@/store'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'
import { get, omit } from 'lodash'

export default {
  components: {
    SubAccountCreateForm,
    vSelect,
    Button,
  },
  data() {
    return {
      actionType: '',
      permissionFormDataObj: {
        Dashboard: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        Accounts: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        Members: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        SubAccounts: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        UpdateCreditAgent: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        UpdateCreditMember: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        Reports: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        LogAgentTransactions: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        LogMemberTransactions: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        Billing: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        AutoPartner: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        PartnersKeys: {
          read: false, update: false, create: false, delete: false, disabled: [],
        },
      },

      checkedAllRead: false,
      checkedAllUpdate: false,
      checkedAllCreate: false,
      checkedAllDelete: false,

      editId: '',
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    permissionsDataTemp() {
      const keys = Object.keys(this.permissionFormDataObj)
      const permissions = []
      for (let index = 0; index < keys.length; index += 1) {
        const key = keys[index];
        permissions.push({
          module: key,
          read: this.permissionFormDataObj[key].read,
          update: this.permissionFormDataObj[key].update,
          create: this.permissionFormDataObj[key].create,
          delete: this.permissionFormDataObj[key].delete,
        })
      }
      return permissions
    },
  },
  mounted() {
    const routeName = this.$route.name
    if (routeName === 'sub-accounts-create') {
      this.actionType = 'CREATE'
    } else {
      this.actionType = 'EDIT'
      this.editId = this.$route.params.id
      this.initEditData()
    }
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    async initEditData() {
      this.$wait.start('loading-get-setting')
      try {
        const { data } = await this.$http.get(`/agent/sub-agent/edit/${this.editId}`)
        if (data.success) {
          const user = data?.data?.user
          this.$refs.form.setFormData({
            username: user.username,
            contact: user.contact,
            password: '',
          })
          this.initEditPermissionData(user.permissions)
        }
      } finally {
        this.$wait.end('loading-get-setting')
      }
    },
    onChangeCheckboxPermissionAll(eventValue, data) {
      // eventValue = false, true
      // data = {
      //   label: "Read",
      //   column: "read",
      //   field: {
      //     key: "read",
      //     label: "Read",
      //   },
      //   isFoot: false,
      // }
      const fieldKey = data.field.key

      const keys = Object.keys(this.permissionFormDataObj)
      for (let index = 0; index < keys.length; index += 1) {
        const keyModule = keys[index];
        const disabledLists = this.permissionFormDataObj[keyModule].disabled
        if (!Array.from(disabledLists).includes(fieldKey)) {
          this.permissionFormDataObj[keyModule][fieldKey] = eventValue
        } else {
          // alway false when disabled
          this.permissionFormDataObj[keyModule][fieldKey] = false
        }
      }
    },
    onChangeCheckboxPermission(eventValue, data) {
      // { "item": { "module": "Billing", "read": false, "update": false, "create": false }, "index": 2, "field": { "key": "read", "label": "Read" }, "unformatted": false, "value": false, "detailsShowing": false, "rowSelected": false }
      this.permissionFormDataObj[data.item.module][data.field.key] = eventValue
      this.tigerCheckedAll()
    },
    initEditPermissionData(userPermissions) {
      const permissionFormDataObjKeys = Object.keys(this.permissionFormDataObj)
      for (let index = 0; index < userPermissions.length; index += 1) {
        const userPermission = userPermissions[index];
        // from server
        // manage
        // create
        // read
        // update
        // delete
        const { subject } = userPermission
        const { action } = userPermission
        if (permissionFormDataObjKeys.includes(subject)) {
          this.permissionFormDataObj[subject][action] = true
        }
      }

      this.tigerCheckedAll()
    },
    tigerCheckedAll() {
      const keys = Object.keys(this.permissionFormDataObj)
      let totalCheckedAllRead = 0
      let totalCheckedAllUpdate = 0
      let totalCheckedAllCreate = 0
      let totalCheckedAllDelete = 0

      let totalDisabledCheckedAllRead = 0
      let totalDisabledCheckedAllUpdate = 0
      let totalDisabledCheckedAllCreate = 0
      let totalDisabledCheckedAllDelete = 0

      for (let index = 0; index < keys.length; index += 1) {
        const keyModule = keys[index];

        const disabledLists = this.permissionFormDataObj[keyModule].disabled

        totalCheckedAllRead += 1
        totalCheckedAllUpdate += 1
        totalCheckedAllCreate += 1
        totalCheckedAllDelete += 1

        const isDisabledRead = Array.from(disabledLists).includes('read')
        const isDisabledUpdate = Array.from(disabledLists).includes('update')
        const isDisabledCreate = Array.from(disabledLists).includes('create')
        const isDisabledDelete = Array.from(disabledLists).includes('delete')
        if (this.permissionFormDataObj[keyModule].read || isDisabledRead) {
          totalCheckedAllRead -= 1
          if (isDisabledRead) {
            totalDisabledCheckedAllRead += 1
          }
        }
        if (this.permissionFormDataObj[keyModule].update || isDisabledUpdate) {
          totalCheckedAllUpdate -= 1
          if (isDisabledUpdate) {
            totalDisabledCheckedAllUpdate += 1
          }
        }
        if (this.permissionFormDataObj[keyModule].create || isDisabledCreate) {
          totalCheckedAllCreate -= 1
          if (isDisabledCreate) {
            totalDisabledCheckedAllCreate += 1
          }
        }
        if (this.permissionFormDataObj[keyModule].delete || isDisabledDelete) {
          totalCheckedAllDelete -= 1
          if (isDisabledDelete) {
            totalDisabledCheckedAllDelete += 1
          }
        }
      }

      this.checkedAllRead = totalCheckedAllRead === 0 && totalDisabledCheckedAllRead !== keys.length
      this.checkedAllUpdate = totalCheckedAllUpdate === 0 && totalDisabledCheckedAllUpdate !== keys.length
      this.checkedAllCreate = totalCheckedAllCreate === 0 && totalDisabledCheckedAllCreate !== keys.length
      this.checkedAllDelete = totalCheckedAllDelete === 0 && totalDisabledCheckedAllDelete !== keys.length
    },
    onReset() {
      this.$refs.form.formReset()
    },
    async onSave() {
      const isValidForm = await this.$refs.form.validationForm()
      if (!isValidForm) {
        return
      }

      const formData = this.$refs.form.getFormData()

      this.$wait.start('loading-on-save')
      try {
        const path = this.actionType !== 'EDIT' ? '/agent/sub-agent/create' : `/agent/sub-agent/edit`

        let body = formData
        Object.assign(body, {
          permissions: this.permissionsDataTemp,
        })

        if (this.actionType === 'EDIT') {
          body.id = this.editId
          body = omit(body, ['requireChangePassword'])
        }

        const { data } = await this.$http.post(path, body)
        if (data.success) {
          this.$swal({
            icon: 'success',
            title: this.$t('ACCOUNT_SAVE_SUCCESS'),
          })

          if (this.actionType !== 'EDIT') {
            this.$router.push({ name: 'sub-accounts' })
          }
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
